import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../news/style.css";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  ORG_PROJECT_RESET,
  REMOVE_MESSAGE,
  RESET_UPDATE_ERRORS,
} from "../../../store/types/Types";
import {
  fetchOrgProject,
  updateOrgProject,
} from "../../../store/asyncAction/OrgProjectAction";

const UpdateOrgsProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [state, setState] = useState({
    language: "",
  });
  const [selectedLn, setSelectedLn] = useState("");
  const dispatch = useDispatch();

  const { loading, redirect, orpStatus, orp, editErrors, message } =
    useSelector((state) => state.OrgProjectReducer);

  useEffect(() => {
    if (orpStatus) {
      setState({
        language: orp.language,
      });
      setValue(orp.body);
      dispatch({ type: ORG_PROJECT_RESET });
    } else {
      dispatch(fetchOrgProject(id));
    }
  }, [orp]);

  const orpsUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateOrgProject({
        body: value,
        language: selectedLn ? selectedLn : state.language,
        id: orp._id,
      })
    );
  };
  useEffect(() => {
    if (editErrors.length > 0) {
      editErrors.map((error) => toast.error(error.msg));
      dispatch({ type: RESET_UPDATE_ERRORS });
    }
    if (message) {
      toast.success(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
  }, [editErrors, message]);

  useEffect(() => {
    if (redirect) {
      navigate("/all-organization-project");
    }
  }, [redirect]);
  return !loading ? (
    <div className="mt-5 " style={{ width: "90%", margin: "auto" }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "14px",
          },
        }}
      />
      <form onSubmit={orpsUpdate}>
        <div className="row">
          <div className="col-md-6">
            <h2 id="pform">
              <span>Edit Organizational Project</span>
            </h2>
            <div className="product-form">
              <div className="mb-3">
                <label className="form-label">Structure Description</label>
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  id="body"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label>Language</label>
              <select
                name="language"
                id="language"
                className="form-control"
                onChange={(e) => setSelectedLn(e.target.value)}
                value={selectedLn ? selectedLn : orp.language}
              >
                <option value="Nepali">Nepali</option>
                <option value="English">English</option>
              </select>
            </div>
            <div className="mb-3">
              <input
                type="submit"
                className="form-control cpb"
                aria-describedby="emailHelp"
                value="Update"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <p>Loading ...</p>
  );
};
export default UpdateOrgsProject;
