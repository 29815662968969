import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../news/style.css";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  ORG_STRUCTURE_RESET,
  REMOVE_MESSAGE,
  RESET_UPDATE_ERRORS,
} from "../../../store/types/Types";
import {
  fetchOrgStructure,
  updateOrgStructure,
} from "../../../store/asyncAction/OrgStructureAction";

const UpdateOrgsStructure = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [state, setState] = useState({
    language: "",
  });
  const [selectedLn, setSelectedLn] = useState("");
  const dispatch = useDispatch();

  const { loading, redirect, orgStatus, org, editErrors, message } =
    useSelector((state) => state.OrgStructureReducer);

  useEffect(() => {
    if (orgStatus) {
      setState({
        language: org.language,
      });
      setValue(org.body);
      dispatch({ type: ORG_STRUCTURE_RESET });
    } else {
      dispatch(fetchOrgStructure(id));
    }
  }, [org]);

  const orgsUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateOrgStructure({
        body: value,
        language: selectedLn ? selectedLn : state.language,
        id: org._id,
      })
    );
  };
  useEffect(() => {
    if (editErrors.length > 0) {
      editErrors.map((error) => toast.error(error.msg));
      dispatch({ type: RESET_UPDATE_ERRORS });
    }
    if (message) {
      toast.success(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
  }, [editErrors, message]);

  useEffect(() => {
    if (redirect) {
      navigate("/all-organization-structure");
    }
  }, [redirect]);
  return !loading ? (
    <div className="mt-5 " style={{ width: "90%", margin: "auto" }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "14px",
          },
        }}
      />
      <form onSubmit={orgsUpdate}>
        <div className="row">
          <div className="col-md-6">
            <h2 id="pform">
              <span>Edit Organizational Structures</span>
            </h2>
            <div className="product-form">
              <div className="mb-3">
                <label className="form-label">Structure Description</label>
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  id="body"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label>Language</label>
              <select
                name="language"
                id="language"
                className="form-control"
                onChange={(e) => setSelectedLn(e.target.value)}
                value={selectedLn ? selectedLn : org.language}
              >
                <option value="Nepali">Nepali</option>
                <option value="English">English</option>
              </select>
            </div>
            <div className="mb-3">
              <input
                type="submit"
                className="form-control cpb"
                aria-describedby="emailHelp"
                value="Update"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <p>Loading ...</p>
  );
};
export default UpdateOrgsStructure;
