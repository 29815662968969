import React from 'react';
import Gallery from 'react-grid-gallery';
import './style.css'
const Gallerys = () => {
    const IMAGES =
        [{
            src: "./images/m1.JPG",
            thumbnail: "./images/m1.JPG",
        },
        {
            src: "./images/m2.JPG",
            thumbnail: "./images/m2.JPG",

        },

        {
            src: "./images/m3.JPG",
            thumbnail: "./images/m3.JPG",
        },
        {
            src: "./images/m4.JPG",
            thumbnail: "./images/m4.JPG",

        },
        {
            src: "./images/m5.JPG",
            thumbnail: "./images/m5.JPG",

        }
        ]
    return (
        <div>
            <Gallery images={IMAGES} />
        </div>
    )
}

export default Gallerys
