import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import '../news/style.css'
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

import { WORKS_RESET, RESET_UPDATE_ERRORS } from '../../../store/types/Types';
import { fetchWork, updateWorks } from '../../../store/asyncAction/WorkAction';


const UpdateWorks = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        language: ''
    });
    const [selectedLn, setSelectedLn] = useState("");
    const dispatch = useDispatch();

    const { loading, redirect, worksStatus, work, editErrors } = useSelector((state) => state.WorksReducer);
    // const { blog,blogStatus } = useSelector((state) => state.FetchBlog);

    // const { editErrors } = useSelector((state) => state.UpdateBlog)

    useEffect(() => {
        if (worksStatus) {
            setState({
                title: work.title,
                language: work.language
            });
            setValue(work.body);
            dispatch({ type: WORKS_RESET })
        } else {
            dispatch(fetchWork(id));
        }
    }, [work]);

    // console.log(selectedUp);
    // console.log(state.upcomming);

    const newsUpdate = (e) => {
        e.preventDefault();
        dispatch(
            updateWorks({
                title: state.title,
                body: value,
                language: selectedLn ? selectedLn : state.language,
                id: work._id
            })
        )
    }
    useEffect(() => {
        if (editErrors.length > 0) {
            editErrors.map((error) => toast.error(error.msg));
            dispatch({ type: RESET_UPDATE_ERRORS })
        }
    }, [editErrors]);

    useEffect(() => {
        if (redirect) {
            navigate('/all-works');
        }
    }, [redirect])
    return !loading ? (
        <div className="mt-5 " style={{ width: '90%', margin: 'auto' }}>
            <Toaster
                position='top-right'
                reverseOrder={false}
                toastOptions={{
                    style: {
                        fontSize: '14px',
                    },
                }}
            />
            <form onSubmit={newsUpdate} >
                <div className="row">
                    <div className="col-md-6">
                        <h2 id="pform"><span>Edit Works </span></h2>
                        <div className="product-form">

                            <div className="mb-3">
                                <label className="form-label">Works Title</label>
                                <input type="text" className="form-control" aria-describedby="emailHelp"
                                    id="title"
                                    name="title"
                                    value={state.title}
                                    onChange={(e) =>
                                        setState({ ...state, title: e.target.value })
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label>Language</label>
                                <select name="language" id="language" className="form-control"
                                    onChange={(e) => setSelectedLn(e.target.value)}
                                    value={selectedLn ? selectedLn : work.language}
                                >
                                    <option value="Nepali">Nepali</option>
                                    <option value="English">English</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Works Description</label>
                                <ReactQuill theme="snow"
                                    value={value}
                                    onChange={setValue} id="body" />
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <input type="submit" className="form-control cpb" aria-describedby="emailHelp"
                                value="Update Works"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : <p>Loading ...</p>;
}
export default UpdateWorks