import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./estyle.css";
import Header1 from "../nav/Header1";
import Header from "../nav/Header";
import Footer from "../nav/footer/Footer";
import ReactHtmlParser from "react-html-parser";
import { fetchOrgStructures } from "../../components/store/asyncAction/OrgStructureAction";
import { fetchOrgProjects } from "../../components/store/asyncAction/OrgProjectAction";

const Abouteng = () => {
  const { orgs } = useSelector((state) => state.OrgStructureReducer);
  const { orps } = useSelector((state) => state.OrgProjectReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrgStructures());
    dispatch(fetchOrgProjects());
  }, [dispatch]);
  return (
    <div>
      <Header1 />
      <Header />
      <div>
        <div className="about_image px-lg-5">
          <img src="./images/c2s.jpg" className="w-100 px-lg-5" alt="" />
        </div>
      </div>
      <div className="introduction pt-5 pb-5">
        <div className="col-md-10 col-lg-10 offset-md-1 offset-lg-1">
          <h2 className="text-center mb-3 mb-lg-1 pt-4">
            <span>Cooperative Introduction</span>
          </h2>
          <p className="mt-md-5 ps-3 pe-3">
            Former Maharanijhoda VDC located in the rural southwest of Jhapa
            district. At present Small Farmers Development Project has been
            started in Gauradaha Municipality 4 and 5 by establishing small
            farmer development project on 10 Kartik 2055 BS. The entire project
            has been registered and handed over from 3rd Baishakh 2060 BS and is
            being operated under the leadership of women. At present, the
            organization has 13 inter-groups, 339 small farmer groups and 2100
            women members. <br />
            <br />
            At the time of its establishment, the organization provided loans
            for personal business operation to make the share members
            self-employed by giving priority to membership expansion and capital
            formation. In the course of progress, organization went ahead by
            conducting training related to agriculture and also programs related
            to social, community and cultural protection. The organization is
            conducting its activities by giving priority to the programs related
            to agriculture by increasing the number of members, increasing the
            transactions and also developing the manpower and management
            capacity. As the main occupation of the share members of the
            organization is agriculture, the organization has taken the strategy
            of operating the project through individual, institutional and
            collaboration between the members and the organization to bring
            employment and prosperity to the members through the agricultural
            profession. <br />
            <br />
            And the current Gauradaha municipality as well as the entire small
            farmer development campaign has succeeded in becoming the identity.
            All the share members of the organization and the committee,
            sub-committee dignitaries and staff involved in its operation and
            management. Many people who have personally assisted in the planning
            and implementation of this campaign have also contributed to bring
            this identity to this stage of identity. The main objective of this
            organization is to bring positive change in the economic condition
            of the members through the programs related to agriculture. The
            organization has envisioned to be a model cooperative for providing
            financial and non-financial services to its members, increasing the
            productivity of agriculture and livestock, modernizing and marketing
            the products. This organization will mobilize rural savings and
            agricultural loans in the coming days. The objective is to promote
            agriculture and livestock production, processing and marketing
            through the use of new technology and skills. Similarly, the
            organization will reduce poverty by increasing the production and
            productivity of agriculture and livestock by developing local labor,
            skills and capital.
          </p>
        </div>
      </div>
      <div className="introduction pt-3 pb-5">
        <div className="col-md-10 col-lg-10 offset-md-1 offset-lg-1">
          <div>
            <h2 className="text-center mb-3 mb-lg-1 pt-4">
              <span>Organizational Structure</span>
            </h2>
            {orgs &&
              orgs.map((o) => {
                if (o.language == "English") {
                  return (
                    <div className="row p-3 sang">
                      <div className="col-md-6 p-3">
                        {ReactHtmlParser(o.body)}
                      </div>
                      <div className="col-md-6 aimage">
                        <img
                          src={`/images/${o.image}`}
                          className="w-100"
                          alt=""
                        />
                      </div>
                    </div>
                  );
                } else {
                  return;
                }
              })}
          </div>
          <h2 className="text-center mb-3 mb-lg-1 pt-5">
            <span>Projects conducted by the organization</span>
          </h2>
          {orps &&
            orps.map((o) => {
              if (o.language == "English") {
                return (
                  <div className="row p-3 sang">
                    <div className="col-md-6 p-3">
                      {ReactHtmlParser(o.body)}
                    </div>
                    <div className="col-md-6 aimage">
                      <img
                        src={`/images/${o.image}`}
                        className="w-100"
                        alt=""
                      />
                    </div>
                  </div>
                );
              } else {
                return;
              }
            })}
          <h2 className="text-center mb-3 mb-lg-1 pt-5">
            <span>Institutional structure</span>
          </h2>
          <div className="col-md-8 offset-md-2 pt-5">
            <img src="./images/imgs.jpg" className="w-100" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Abouteng;
