import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavHead from '../../nav/NavHead';
import NavLink from '../../nav/NavLink';
import { Link } from 'react-router-dom';
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import '../news/style.css'
import { CLOSE_LOADER, REDIRECT_FALSE, REMOVE_MESSAGE, SET_LOADER, SET_MESSAGE } from '../../../store/types/Types';
import { fetchBanners } from '../../../store/asyncAction/NewsAction';


const Allbanners = () => {


  const { token } = useSelector((state) => state.AuthReducer);

  const { loading, message, redirect, banners } = useSelector(state => state.NewsReducer);

  const dispatch = useDispatch();

  const deleteBanner = async (id) => {
    const confirm = window.confirm('Are you really want to delete this banner ?');
    if (confirm) {
      dispatch({ type: SET_LOADER });
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const {
          data: { msg },
        } = await axios.delete(`/api/banner/${id}`, config);
        dispatch(fetchBanners());
        dispatch({ type: SET_MESSAGE, payload: msg });
      } catch (error) {
        dispatch({ type: CLOSE_LOADER });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (redirect) {
      dispatch({ type: REDIRECT_FALSE });
    }
    if (message) {
      toast.success(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
  }, [message, dispatch, redirect]);

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);


  return <div className='dash-contact row w-100'>
    <div className="col-md-2 dash-nav">
      <NavLink />
    </div>
    <div className="col-md-10 dash-details">
      <NavHead />
      <hr />
      <div className="dash-screen">
        <Toaster
          position='top-center'
          reverseOrder={false}
          toastOptions={{
            style: {
              fontSize: '14px',
            },
          }}
        />
        <div className="add-button float-end me-4 m-2">
          <button style={{ backgroundColor: '#000', color: '#fff', padding: '5px 20px' }}>
            <Link to='/create-banner' style={{ color: '#fff', textDecoration: 'none' }}>Add Banner</Link>
          </button>
        </div>
        {loading ? <p>loading ....</p> : <table className="table table-dark ">
          <thead>
            <tr>
              <th scope="col">S.N</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {banners.map((b, i) => (
              <tr key={b._id}>
                <td>{i + 1}</td>
                <td><img src={`/images/${b.image}`} height="100px" width="100px" alt="book" /></td>
                <td style={{ cursor: 'pointer' }}>
                  <i onClick={() => {
                    deleteBanner(b._id)
                  }} className="far fa-trash-alt" id="deleteAction"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>}
      </div>
    </div>
  </div>;
};

export default Allbanners;
