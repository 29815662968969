import React from 'react'
import Footer from '../nav/footer/Footer'
import Header from '../nav/Header'
import Header1 from '../nav/Header1'

import './estyle.css'

const Edownload = () => {
    return (
        <div>
            <Header1 />
            <Header />
            <div className="our_works">
                <div className="our_work ps-md-5 pe-md-5">
                    <img src="./images/c2s.jpg" className='w-100 mb-5' alt="" />
                </div>
                <div className="works">
                    <div className="w-100 pb-5">
                        <div className="col-md-10 offset-md-1">
                            <div className="work_head">
                                <h2 className='work_title text-center'><span>Download</span></h2>
                            </div>
                            <div className="download pt-5">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Lorem ipsum dolor sit amet consectetur
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus perspiciatis eum in maxime incidunt a repellat dolore! Voluptatibus eaque ratione, facere nam, sunt placeat quibusdam autem impedit laudantium quam dolorem.
                                                <div className="download-button">
                                                    <button>download</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Lorem ipsum dolor sit amet consectetur
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                <div className="download-button">
                                                    <button>download</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Lorem ipsum dolor sit amet consectetur
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                <div className="download-button">
                                                    <button>download</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Edownload