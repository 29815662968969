import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import AuthReducer from "./reducer/AuthReducer";
import { NewsReducer } from "./reducer/NewsReducer";
import { WorksReducer } from "./reducer/WorkReducer";
import { OrgStructureReducer } from "./reducer/OrgStructureReducer";
import { OrgProjectReducer } from "./reducer/OrgProjectReducer";

const rootReducers = combineReducers({
  AuthReducer,
  NewsReducer,
  WorksReducer,
  OrgStructureReducer,
  OrgProjectReducer,
});
const middlewares = [thunkMiddleware];
const Store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(...middlewares))
);
export default Store;
