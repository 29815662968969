import React from "react";
import "./eheader.css";
import { NavLink } from "react-router-dom";

const Header1 = () => {
  return (
    <div className="first-header">
      <div className="location">
        <li>
          <i className="fas fa-map-marker-alt" id="icon"></i>{" "}
          <span>Gauradaha-5,Jhapa ,Nepal</span>
        </li>
      </div>
      <div className="contact">
        <li>
          <i className="fas fa-envelope" id="icon"></i>{" "}
          <span>sfaclmaharanijhoda@gmail.com</span>
        </li>
        <li>
          <i className="fas fa-phone-alt" id="icon"></i>{" "}
          <span> 023-419017</span>
        </li>
        <li className="fhead">
          {" "}
          &nbsp; &nbsp;
          <NavLink
            exact="true"
            to="/"
            className={({ isActive }) => (isActive ? "link-active" : "link")}
          >
            NP
          </NavLink>{" "}
          |
          <NavLink
            to="/english"
            className={({ isActive }) => (isActive ? "link-active" : "link")}
          >
            &nbsp; EN
          </NavLink>
        </li>
      </div>
    </div>
  );
};

export default Header1;
