import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavHead from "../../nav/NavHead";
import NavLink from "../../nav/NavLink";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";
import "../news/style.css";
import {
  CLOSE_LOADER,
  REDIRECT_FALSE,
  REMOVE_MESSAGE,
  SET_LOADER,
  SET_MESSAGE,
} from "../../../store/types/Types";
import { fetchOrgStructures } from "../../../store/asyncAction/OrgStructureAction";

const AllOrgStructure = () => {
  const [keyword, setKeyword] = useState("");

  const { token } = useSelector((state) => state.AuthReducer);

  const { loading, message, redirect, orgs } = useSelector(
    (state) => state.OrgStructureReducer
  );

  // const { blogs } = useSelector(state => state.FetchBlogs);

  const dispatch = useDispatch();

  const deleteOrgs = async (id) => {
    const confirm = window.confirm("Are you really want to delete?");
    if (confirm) {
      dispatch({ type: SET_LOADER });
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const {
          data: { msg },
        } = await axios.delete(
          `/api/deleteOrganizationalStructure/${id}`,
          config
        );
        dispatch(fetchOrgStructures());
        dispatch({ type: SET_MESSAGE, payload: msg });
      } catch (error) {
        dispatch({ type: CLOSE_LOADER });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (redirect) {
      dispatch({ type: REDIRECT_FALSE });
    }
    if (message) {
      toast.success(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
  }, [message, dispatch, redirect]);

  useEffect(() => {
    dispatch(fetchOrgStructures());
  }, [dispatch]);

  const handleChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  return (
    <div className="dash-contact row w-100">
      <div className="col-md-2 dash-nav">
        <NavLink />
      </div>
      <div className="col-md-10 dash-details">
        <NavHead />
        <hr />
        <div className="news_button float-end">
          <Link to="/add-org-structure">
            <button>Add Organizational Structure</button>
          </Link>
        </div>
        <div className="dash-screen">
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              style: {
                fontSize: "14px",
              },
            }}
          />
          <div className="search">
            <input
              type="search"
              placeholder="Filter"
              value={keyword}
              onChange={handleChange}
              className="form-control mb-4"
            ></input>
          </div>
          {loading ? (
            <p>Loading..</p>
          ) : (
            <table className="table table-dark ">
              <thead>
                <tr>
                  <th scope="col">S.N</th>
                  <th scope="col">Language</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {orgs &&
                  orgs.map((n, i) => (
                    <tr key={n._id}>
                      <td>{i + 1}</td>
                      <td className="title">{n.language}</td>
                      <td className="description">
                        {ReactHtmlParser(n.body.slice(0, 200))}
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        <Link
                          id="imgupdate"
                          style={{ textDecoration: "none" }}
                          to={`/updateorgsImage/${n._id}`}
                          className="me-5"
                        >
                          <img
                            src={`/images/${n.image}`}
                            height="100px"
                            width="100px"
                            alt="blog"
                          />
                          &nbsp;&nbsp; &nbsp;<i className="fas fa-images "></i>
                        </Link>
                        <Link
                          id="updateAction"
                          to={`/update-organizationstruct/${n._id}`}
                          className="me-4"
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </Link>
                        <i
                          onClick={() => {
                            deleteOrgs(n._id);
                          }}
                          className="far fa-trash-alt"
                          id="deleteAction"
                        ></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllOrgStructure;
