export const CREATE_ERRORS = "CREATE_ERRORS";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const SET_LOADER = "SET_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const REDIRECT_TRUE = "REDIRECT_TURE";
export const REDIRECT_FALSE = "REDIRECT_FALSE";
export const SET_MESSAGE = "SET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const SET_NEWS = " SET_NEWS";
export const SET_NEW = " SET_NEW";
export const SET_DETAILS = " SET_DETAILS";
export const NEWS_RESET = " NEWS_RESET";
export const NEWS_REQUEST = " NEWS_REQUEST";
export const SET_UPDATE_ERRORS = " SET_UPDATE_ERRORS";
export const RESET_UPDATE_ERRORS = " RESET_UPDATE_ERRORS";
export const UPDATE_IMAGE_ERRORS = " UPDATE_IMAGE_ERRORS";
export const RESET_UPDATE_IMAGE_ERRORS = " RESET_UPDATE_IMAGE_ERRORS";
export const SET_WORKS = " SET_WORKS";
export const SET_WORK = " SET_WORK";
export const WORKS_RESET = " WORKS_RESET";
export const WORKS_REQUEST = " WORKS_REQUEST";
export const SET_BANNERS = " SET_BANNERS";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_ORG_STRUCTURES = "SET_ORG_STRUCTURES";
export const SET_ORG_STRUCTURE = "SET_ORG_STRUCTURE";
export const ORG_STRUCTURE_REQUEST = "ORG_STRUCTURE_REQUEST";
export const ORG_STRUCTURE_RESET = "ORG_STRUCTURE_RESET";
export const SET_ORG_PROJECTS = "SET_ORG_PROJECTS";
export const SET_ORG_PROJECT = "SET_ORG_PROJECT";
export const ORG_PROJECT_REQUEST = "ORG_PROJECT_REQUEST";
export const ORG_PROJECT_RESET = "ORG_PROJECT_RESET";
