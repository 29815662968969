import React from 'react'
import Footer from '../nav/footer/Footer'
import Header from '../nav/Header'
import Header1 from '../nav/Header1'

import './estyle.css'

const EAnnouncement = () => {
    return (
        <div>
            <Header1 />
            <Header />
            <div className="our_works">
                <div className="our_work ps-md-5 pe-md-5">
                    <img src="./images/m1s.JPG" className='w-100 mb-5' alt="" />
                </div>
                <div className="works">
                    <div className="w-100 pb-5">
                        <div className="col-md-10 offset-md-1">
                            <div className="work_head">
                                <h2 className='work_title text-center'><span>Announcements</span></h2>
                            </div>
                            <div className="work-card">
                                <p className="p-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque enim rerum, quasi delectus ipsum facere fuga nulla aliquam ipsam nam laboriosam dicta ut quaerat, ratione quam modi voluptates, similique animi?
                                </p>
                                <p className="p-3">
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius quisquam sit, doloribus delectus consequatur mollitia. Eaque autem facere, esse, aperiam labore, doloremque error id ad perferendis amet nihil recusandae. Odit!
                                </p>
                                <p className="p-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde facilis adipisci neque nulla atque aspernatur deserunt dolore provident consequuntur delectus ipsa nesciunt dolores, eius optio est nostrum officiis incidunt temporibus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EAnnouncement