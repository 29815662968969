import React from 'react'
import Footer from '../components/nav/footer/Footer'
import Header from '../components/nav/Header'
import Header1 from '../components/nav/Header1'
import './style.css'

const Announcement = () => {
    return (
        <div>
            <Header1 />
            <Header />
            <div className="our_works">
                <div className="our_work ps-md-5 pe-md-5">
                    <img src="./images/m1s.JPG" className='w-100 mb-5' alt="" />
                </div>
                <div className="works">
                    <div className="w-100 pb-5">
                        <div className="col-md-10 offset-md-1">
                            <div className="work_head">
                                <h2 className='work_title text-center'><span>घोषणाहरु</span></h2>
                            </div>
                            <div className="work-card">
                                <p className="p-3">
                                    झापा जिल्लाको पश्चिम दक्षिणको ग्रामिण भेगमा स्थित साविक महारानीझोडा गा.वि.स. हाल गौरादह नगरपालिका ४ र ५ मा २०५५ साल कार्तिक १०
                                    मा साना किसान विकास आयोजनाको स्थापना गरी साना किसान परिवारका महिलाहरु लाई समुहमा आवद्ध गराई प्रारम्भ
                                    गरेको कार्यक्रम समय र विकासक्रम तथा सदस्यहरुको चाहाना अनुसार २०५९ पौष १७ मा डिभिजन सहकारी कार्यलयमा विधिवत् रुपमा
                                    साना किसान सहकारी संस्था लि. दर्ता गरि उक्त आयोजनाको सम्पूर्ण कारोवार २०६० वैशाख ३ बाट हस्तान्तरण गरि महिलाहरुकै नेत्तृत्वमा
                                    संचालित छ । हाल संस्थामा १३ वटा अन्तर समुह, ३ सय ४ वटा साना किसान समुह
                                    गठन भै १ हजार ९ सय ५६ जना महिला सदस्यहरु आवद्ध हुनुहुन्छ ।
                                </p>
                                <p className="p-3">
                                    यस संस्थाले स्थापना कालमा सदस्य विस्तार र पूँजी निर्माणलाई प्राथमिकतामा राखि सेयर सदस्यहरु लाई स्वरोजगार बनाउन व्याक्तिगत
                                    व्यावसाय संचालनका लागि कर्जा प्रदान गर्याे । क्रमस अगाडि बड्ने क्रममा कृषिसंग सम्बन्धित तालिम र सामाजिक सामुदायिक तथा
                                    सांस्कृतीक संरक्षणसंग सम्बन्धित कार्यक्रमहरु समेत संचालन गर्दै अगाडि बढ्यो । संस्थामा आवद्ध सदस्यहरुको संख्यामा बृद्धि, कारोवारेमा
                                    बृद्धि तथा जनशक्ती र व्यवस्थापन क्षमतामा समेत विकास गरी कृषिसंग सम्बन्धित कार्यक्रमहरु लाई प्राथमिकतामा राखेर आफ्ना गतिबिधिहरु
                                    सञ्चालन गरिरहेको छ ।
                                </p>
                                <p className="p-3">
                                    संस्थाले आफ्ना सदस्यहरुलाई वित्तिय तथा गैर वित्तिय सेवा प्रदान गरी कृषि तथा पशुको उत्पादन, उत्पादकत्व वृद्धिगरि उत्पादित बस्तुहरुको
                                    अद्यौगीकरण र वजारिकरण गर्ने एक नमुना सहकारी हुने परिकल्पना अगाडि सारेको छ ।  यस संस्थाले आगामी दिनमा ग्रामिण वचत तथा कृषि
                                    ऋण परिचालन । नयाँ प्रविधि र सिपको प्रयोग  र सहकारी खेतीको माध्यमबाट कृषि र पशु उत्पादन , प्रशोधन र वजारीकरण गर्ने उद्धेश्य अगाडि
                                    बढाएको छ । यसैगरी संस्थाले स्थानीय श्रम, सीप र पुँजीको विकास गरी सो को परिचालनवाट कृषि तथा पशुको उत्पादन र उत्पादकत्व वृद्धि गर्दै गरिवी
                                    न्यूनिकरण गर्ने ।
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Announcement