import React from 'react'
import Footer from '../nav/footer/Footer'
import Header from '../nav/Header'
import Header1 from '../nav/Header1'
import './estyle.css'

const Epatners = () => {
    return (
        <div>
            <Header1 />
            <Header />
            <div className="our_works">
                <div className="our_work ps-md-5 pe-md-5">
                    <img src="./images/cs.jpg" className='w-100 mb-5' alt="" />
                </div>
                <div className="works">
                    <div className="w-100 pb-5">
                        <div className="col-md-10 offset-md-1">
                            <div className="work_head">
                                <h2 className='work_title text-center'><span> Partners</span></h2>
                            </div>
                            <div className="allpartners pt-5">
                                <div className="all_partner pt-5 mt-2">
                                    <div className="govpart">
                                        <p><span>Government :</span></p>
                                    </div>
                                    <div className="government_partners">
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="all_partner">
                                    <div className="govpart">
                                        <p><span>Government Agency :</span></p>
                                    </div>
                                    <div className="government_partners">
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="all_partner ">
                                    <div className="govpart">
                                        <p><span>Doner :</span></p>
                                    </div>
                                    <div className="government_partners">
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="all_partner ">
                                    <div className="govpart">
                                        <p><span>Private :</span></p>
                                    </div>
                                    <div className="government_partners">
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                        <div className="partner">
                                            <img src="./images/logo.png" alt="" />
                                            <p className='text-center'>lorem ipsum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Epatners