import axios from 'axios';
import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    SET_MESSAGE,
    REMOVE_ERRORS,
    SET_WORKS,
    SET_UPDATE_ERRORS,
    WORKS_REQUEST,
    SET_WORK,
    UPDATE_IMAGE_ERRORS,
    SET_DETAILS
} from '../types/Types'

export const worksCreate = (postData) => {
    return async (dispatch, getState) => {
        const {
            AuthReducer: { token },
        } = getState()
        dispatch({ type: SET_LOADER })
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data: { msg } } = await axios.post('/api/works', postData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REMOVE_ERRORS })
            dispatch({ type: REDIRECT_TRUE });
            dispatch({ type: SET_MESSAGE, payload: msg })
        } catch (error) {
            console.log(error.response)
            const errors = error.response.data;
            dispatch({ type: CLOSE_LOADER })
            dispatch({ type: CREATE_ERRORS, payload: errors })
        }
    }
}


export const fetchWorks = () => {
    return async (dispatch, getState) => {
        const { AuthReducer: { token } } = getState();
        dispatch({ type: SET_LOADER });
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            const { data: { response },
            } = await axios.get('/api/works', config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_WORKS, payload: { response } })
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
        }
    }
}

export const fetchWork = (id) => {
    return async (dispatch, getState) => {
        const {
            AuthReducer: { token },
        } = getState();
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        dispatch({ type: SET_LOADER });
        try {
            const {
                data: { work },
            } = await axios.get(`/api/work/${id}`, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_WORK, payload: work });
            dispatch({ type: WORKS_REQUEST });
        } catch (error) {
            dispatch({ type: CLOSE_LOADER });
            console.log(error.message);
        }
    };
};


export const updateWorks = (editData) => {
    return async (dispatch, getState) => {
        const {
            AuthReducer: { token },
        } = getState();
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        dispatch({ type: SET_LOADER });
        try {
            const {
                data
            } = await axios.post('/api/workupdate', editData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REDIRECT_TRUE })
            dispatch({ type: SET_MESSAGE, payload: data.msg })
        } catch (error) {
            const { response: {
                data: { errors },
            } } = error;
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_UPDATE_ERRORS, payload: errors });
            console.log(error.response);
        }
    }
}

export const updateImageWorks = (updateData) => {
    return async (dispatch, getState) => {
        const {
            AuthReducer: { token },
        } = getState();
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        dispatch({ type: SET_LOADER });
        try {
            const {
                data: { msg },
            } = await axios.post('/api/updatewImage', updateData, config);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: REDIRECT_TRUE });
            dispatch({ type: SET_MESSAGE, payload: msg });
        } catch (error) {
            const {
                response: {
                    data: { errors },
                },
            } = error;
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: UPDATE_IMAGE_ERRORS, payload: errors });
        }
    }
}

export const worksDetails = (slug) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADER });
        try {
            const { data: { news } } = await axios.get(`/api/work-details/${slug}`);
            dispatch({ type: CLOSE_LOADER });
            dispatch({ type: SET_DETAILS, payload: news });
        } catch (error) {
            dispatch({ type: CLOSE_LOADER })
            console.log(error)
        }
    }
}
