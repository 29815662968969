import React from 'react';
import './eheader.css'
import { NavLink } from 'react-router-dom';
import logo from '../../img/logo1.png'

const Header = () => {
    return <div className="navss enave">
        <nav className="navbar navbar-expand-lg header">
            <div className="econtainerss" style={{ display: 'flex', flexWrap: 'wrap' }}>

                <NavLink to='/english' id='link' className="navbar-brand  d-flex">
                    <img src={logo} alt="" style={{ height: '90px', width: '95px' }} />
                    <h4 className='ltd-name mt-2 pt-1'><span style={{ fontSize: '23px' }}>Maharanijhoda</span><br />Small Farmer Agriculture <br /> Cooperative Ltd.</h4>
                    {/* <img alt="logo of co-operative ltd." height={75} width={75} /> */}
                </NavLink>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <li>
                            <NavLink
                                exact="true"
                                to="/english"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                Home Page
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/about-us"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                About Us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/ourworks"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                Our Works
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/enews-section"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                News
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/eannouncement"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                Announcements
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to="/edownload"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                Download
                            </NavLink>

                        </li>
                        <li>
                            <NavLink
                                to="/epartners"
                                className={({ isActive }) => (isActive ? "link-active" : "link")}
                            >
                                Partner
                            </NavLink>
                        </li>
                    </div>
                </div>
            </div>
        </nav>
    </div>;
};

export default Header;
