import axios from "axios";
import {
  SET_LOADER,
  CLOSE_LOADER,
  CREATE_ERRORS,
  REDIRECT_TRUE,
  SET_MESSAGE,
  REMOVE_ERRORS,
  SET_ORG_PROJECTS,
  SET_UPDATE_ERRORS,
  ORG_PROJECT_REQUEST,
  SET_ORG_PROJECT,
  UPDATE_IMAGE_ERRORS,
} from "../types/Types";

export const orgProjectCreate = (postData) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    dispatch({ type: SET_LOADER });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const {
        data: { msg },
      } = await axios.post("/api/organizationProject", postData, config);
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REMOVE_ERRORS });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: SET_MESSAGE, payload: msg });
    } catch (error) {
      console.log(error.response);
      const errors = error.response.data;
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: CREATE_ERRORS, payload: errors });
    }
  };
};

export const fetchOrgProjects = () => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    dispatch({ type: SET_LOADER });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const {
        data: { response },
      } = await axios.get("/api/organizationProjects", config);
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: SET_ORG_PROJECTS, payload: { response } });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const fetchOrgProject = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        data: { orp },
      } = await axios.get(`/api/organizationProject/${id}`);

      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: SET_ORG_PROJECT, payload: orp });
      dispatch({ type: ORG_PROJECT_REQUEST });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
      console.log(error.message);
    }
  };
};

export const updateOrgProject = (editData) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const { data } = await axios.post(
        "/api/organizationProjectUpdate",
        editData,
        config
      );
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: SET_MESSAGE, payload: data.msg });
    } catch (error) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: SET_UPDATE_ERRORS, payload: errors });
      console.log(error.response);
    }
  };
};

export const updateImageOProject = (updateData) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const {
        data: { msg },
      } = await axios.post("/api/updateopImage", updateData, config);
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: SET_MESSAGE, payload: msg });
    } catch (error) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: UPDATE_IMAGE_ERRORS, payload: errors });
    }
  };
};
