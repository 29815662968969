// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/login.webp";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login{\n    height: 100vh;\n    background-color: rgba(7, 7, 99, 0.945);\n}\n.login-section{\n    padding-top: 150px;\n    height: 100vh;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-size: cover;\n    opacity: .7;\n}\n.login-form{\n    background-color: #fff;\n    padding: 50px;\n}\n.form-control:focus {\n    color: #212529;\n    background-color: #fff;\n    border-color: none;\n    outline: 0;\n    box-shadow: none;\n}\n.login-form button{\n    background-color: brown;\n    color: #fff;\n    font-size: 19px;\n    padding: 4px 20px;\n    border: none;\n}", "",{"version":3,"sources":["webpack://src/pages/auth/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uCAAuC;AAC3C;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,6DAAiD;IACjD,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".login{\n    height: 100vh;\n    background-color: rgba(7, 7, 99, 0.945);\n}\n.login-section{\n    padding-top: 150px;\n    height: 100vh;\n    background: url('../../img/login.webp') no-repeat;\n    background-size: cover;\n    opacity: .7;\n}\n.login-form{\n    background-color: #fff;\n    padding: 50px;\n}\n.form-control:focus {\n    color: #212529;\n    background-color: #fff;\n    border-color: none;\n    outline: 0;\n    box-shadow: none;\n}\n.login-form button{\n    background-color: brown;\n    color: #fff;\n    font-size: 19px;\n    padding: 4px 20px;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
