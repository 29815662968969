import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWorks } from "../../components/store/asyncAction/WorkAction";
import Footer from "../nav/footer/Footer";
import Header from "../nav/Header";
import ReactHtmlParser from "react-html-parser";
import Header1 from "../nav/Header1";
import "./estyle.css";

const Eourworks = () => {
  const { works, loading } = useSelector((state) => state.WorksReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWorks());
  }, [dispatch]);

  let count = 0;

  return (
    <div>
      <Header1 />
      <Header />
      <div className="our_works">
        <div className="our_work ps-md-5 pe-md-5">
          <img src="./images/m1s.JPG" className="w-100 mb-5" alt="" />
        </div>
        <div className="works">
          <div className="row w-100 pb-5">
            <div className="work_head pb-5">
              <h2 className="work_title text-center">
                <span>Our Works</span>
              </h2>
            </div>
            <div className="col-md-10 offset-md-1">
              {works &&
                works.map((w) => {
                  if (w.language == "English") {
                    if (count % 2 === 0) {
                      count++;
                      return (
                        <div
                          className={count % 2 === 0 ? "left" : "right"}
                          key={w._id}
                        >
                          <div className="ccontent">
                            <div>
                              <div className="work-head">
                                <h3>
                                  <span>{w.title}</span>
                                </h3>
                              </div>
                              <div className="work-content">
                                <p>{ReactHtmlParser(w.body)}</p>
                              </div>
                            </div>
                          </div>
                          <div className="cimage aimage">
                            <img
                              src={`/images/${w.image}`}
                              className="w-100 mt-3"
                              alt="Work_image"
                            />
                          </div>
                        </div>
                      );
                    } else {
                      count++;
                      return (
                        <div
                          className={count % 2 === 0 ? "left" : "right"}
                          key={w._id}
                        >
                          <div className="ccontent">
                            <div>
                              <div className="work-head">
                                <h3>
                                  <span>{w.title}</span>
                                </h3>
                              </div>
                              <div className="work-content">
                                <p>{ReactHtmlParser(w.body)}</p>
                              </div>
                            </div>
                          </div>
                          <div className="cimage aimage">
                            <img
                              src={`/images/${w.image}`}
                              className="w-100 mt-3"
                              alt="Work_image"
                            />
                          </div>
                        </div>
                      );
                    }
                  } else return;
                })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Eourworks;
