import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/admin/Dashboard";
import Allbanners from "./components/admin/pages/banner/Allbanners";
import CreateBanner from "./components/admin/pages/banner/CreateBanner";
import AllNews from "./components/admin/pages/news/AllNews";
import CreateNews from "./components/admin/pages/news/CreateNews";
import UpdateNews from "./components/admin/pages/news/UpdateNews";
import UpdateNewsImage from "./components/admin/pages/news/UpdateNewsImage";
import AllNotification from "./components/admin/pages/notification/AllNotification";
import CreateNotification from "./components/admin/pages/notification/CreateNotification";
import AllWorks from "./components/admin/pages/works/AllWorks";
import CreateWorks from "./components/admin/pages/works/CreateWork";
import UpdateWorks from "./components/admin/pages/works/UpdateWorks";
import UpdateWorksImage from "./components/admin/pages/works/UpdateWorksImage";
import Popup from "./components/nav/Popup";
import PrivateRoute from "./components/routes/PrivateRoute";
import Abouteng from "./english/pages/Abouteng";
import EAnnouncement from "./english/pages/EAnnouncement";
import Edownload from "./english/pages/Edownload";
import EnewsSec from "./english/pages/EnewsSec";
import Eourworks from "./english/pages/Eourworks";
import Epatners from "./english/pages/Epartner";
import Homeeng from "./english/pages/Homeeng";
import Details from "./english/pages/innerpage/Details";
import About from "./pages/About";
import Announcement from "./pages/Announcement";
import Login from "./pages/auth/Login";
import Download from "./pages/Download";
import Home from "./pages/Home";
import Ndetails from "./pages/innerpage/Ndetails";
import NewsSection from "./pages/NewsSection";
import OurWorks from "./pages/OurWorks";
import Patners from "./pages/Patners";
import AllOrgStructure from "./components/admin/pages/orgStructure/AllOrgStructure";
import CreateOrgStructure from "./components/admin/pages/orgStructure/CreateOrgStructure";
import UpdateOrgsStructure from "./components/admin/pages/orgStructure/UpdateOrgsStructure";
import UpdateOrgsImage from "./components/admin/pages/orgStructure/UpdateOrgsImage";
import AllOrgProject from "./components/admin/pages/orgProjects/AllOrgProject";
import CreateOrgProject from "./components/admin/pages/orgProjects/CreateOrgProject";
import UpdateOrgsProject from "./components/admin/pages/orgProjects/UpdateOrgProject";
import UpdateOrgpImage from "./components/admin/pages/orgProjects/UpdateOrgpImage";

function App() {
  return (
    <>
      <Popup />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/english" element={<Homeeng />} />

          <Route path="/about-us" element={<Abouteng />} />
          <Route path="/news-details/:id" element={<Details />} />
          <Route path="/nnews-details/:id" element={<Ndetails />} />

          <Route path="/our-works" element={<OurWorks />} />
          <Route path="/ourworks" element={<Eourworks />} />
          <Route path="/news-section" element={<NewsSection />} />
          <Route path="/enews-section" element={<EnewsSec />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/eannouncement" element={<EAnnouncement />} />
          <Route path="/download" element={<Download />} />
          <Route path="/edownload" element={<Edownload />} />
          <Route path="/partners" element={<Patners />} />
          <Route path="/epartners" element={<Epatners />} />

          <Route path="/admin/login" element={<Login />} />

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/all-news" element={<AllNews />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/add-news" element={<CreateNews />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/update-news/:id" element={<UpdateNews />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/updatenImage/:id" element={<UpdateNewsImage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/all-works" element={<AllWorks />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/add-works" element={<CreateWorks />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/update-works/:id" element={<UpdateWorks />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/updatewImage/:id" element={<UpdateWorksImage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/all-banners" element={<Allbanners />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/create-banner" element={<CreateBanner />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/all-notifications" element={<AllNotification />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/create-notification"
              element={<CreateNotification />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/all-organization-structure"
              element={<AllOrgStructure />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/add-org-structure" element={<CreateOrgStructure />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/update-organizationstruct/:id"
              element={<UpdateOrgsStructure />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/updateorgsImage/:id" element={<UpdateOrgsImage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/all-organization-project"
              element={<AllOrgProject />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/add-org-project" element={<CreateOrgProject />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/update-organizationproject/:id"
              element={<UpdateOrgsProject />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/updateorgpImage/:id" element={<UpdateOrgpImage />} />
          </Route>
          {/* nepali */}
          <Route path="/aboutus" element={<About />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
